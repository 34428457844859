import { get } from 'helpers/api_helper'
import { useEffect, useState } from 'react';
import {
    Modal,
    ModalHeader
} from "reactstrap"

const getFinalDate = (date) => {
    var givenDate = new Date(date); // Replace '2023-09-10' with your desired date
    givenDate.setDate(givenDate.getDate() + 15);
    return givenDate.toISOString().split('T')[0];
}

localStorage.setItem('notice', localStorage.getItem('notice') == "true" ? true : false)

const RenewalBar = () => {
    const [status, setStatus] = useState(null);
    const [subscribemodal, setSubscribemodal] = useState(false)

    useEffect(async () => {
        const res = await get(`/status/${localStorage.getItem('client_code')}`);
        if(res.status_code == 200){
            setStatus(res?.data)
            if(localStorage.getItem('notice') == "false"){
                console.log(parseInt(res?.data?.notice))
                setSubscribemodal(parseInt(res?.data?.notice) > 0? true : failse)
            }
        }
    },[])

    if(status == null){
        return false
    }

    return (
        <div style={{background:'#BB2525', height: '28px'}}>
            <p className="text-white p-1 text-center">
                Welcome <b>{localStorage.getItem('client_name')}</b>, Your subscription <b>{status?.package}</b> expires on <b>{status?.renewal}</b>. Don`t lose out on the perks! Click <a href={status?.payment} rel="noreferrer" target="_blank" className="text-black" role="button"><b>Renew Now</b></a> to keep the benefits coming.
            </p>

            {/* subscribe ModalHeader */}
            {
                <Modal
                    isOpen={subscribemodal}
                    role="dialog"
                    autoFocus={true}
                    centered
                    data-toggle="modal"
                    toggle={() => {
                        setSubscribemodal(!subscribemodal)
                    }}
                >
                    <div className="modal-content">
                    <div className="modal-header border-bottom-0">
                        <ModalHeader
                        toggle={() => {
                            setSubscribemodal(!subscribemodal)
                            localStorage.setItem('notice', true);
                        }}
                        ></ModalHeader>
                    </div>
                    <div className="modal-body">
                        <div className="text-center mb-4">
                        <div className="avatar-md mx-auto mb-4">
                            <div className="avatar-title bg-light rounded-circle text-danger h1">
                            <i className="mdi mdi-lock-open-alert"></i>
                            </div>
                        </div>

                        <div className="row justify-content-center">
                            <div className="col-xl-10">
                                <h4 className="text-danger"> Important Notice !</h4>
                                <p className="text-muted font-size-14 mb-4">Starting <b style={{fontSize: '16px'}}>{getFinalDate(status?.renewal)}</b>, you`ll need to renew your subscription to continue using our applications. Without renewal, access will no longer be available.</p>

                                <a title="renewal pay" color="primary" rel="noreferrer" target="_blank" href="https://razorpay.com/payment-button/pl_LKsvl67l9KmI2x/view/?utm_source=payment_button&utm_medium=button&utm_campaign=payment_button" className="btn btn-primary" id="button-addon2">
                                    <i className="mdi mdi-currency-inr"></i> RENEWAL NOW
                                </a>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </Modal>
            }
        </div>
    )
}

export default RenewalBar;