import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"


// Company
import Master from "store/master/reducers"
import Employee from 'modules/Employee/store/reducers';
import Attendance from 'modules/Attendance/store/reducers';
import Visits from "modules/Punches/store/reducers";

import Dashbaord from "modules/Dashboard/store/reducers";

import Clients from "modules/admin/store/reducer";

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Master,
  Employee,
  Attendance,
  Visits,
  Dashbaord,
  Clients
})

export default rootReducer
