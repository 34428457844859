import React from "react"
import { Row, Col, Card, CardBody, CardTitle, Button } from "reactstrap"
import "./datatables.scss"

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';


const MyExportCSV = (props) => {
  const handleClick = () => {
    // eslint-disable-next-line react/prop-types
    props.onExport();
  };
  return <button className="btn" onClick={ handleClick }>Export to CSV</button>
};

// eslint-disable-next-line react/prop-types
const Datatables = ({ handdleAddButton, title, columns, rows, isAdd = true, isDate = false, returnDate = false, getLatlong = false, searchActive = true}) => {
  const defaultSorted = [{
    dataField: 'id',
    order: 'asc'
  }];

  // Custom Pagination Toggle
  const sizePerPageList = [
    { text: '5', value: 5 },
    { text: '10', value: 10 },
    { text: '15', value: 15 },
    { text: '20', value: 20 },
    { text: '25', value: 50 },
    // eslint-disable-next-line react/prop-types
    { text: 'All', value: rows?.length }
  ];
  
  const pageOptions = {
    sizePerPage: 10,
    // eslint-disable-next-line react/prop-types
    totalSize: rows?.length,
    custom: true,
  }

  const { SearchBar } = Search;

  const changeDate = (date) => {
    returnDate(date)
  }

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
            <PaginationProvider
              pagination={paginationFactory(pageOptions)}
              keyField='id'
              columns={columns}
              data={rows}
              >
              {({ paginationProps, paginationTableProps }) => ( 
                  <ToolkitProvider
                    keyField='id'
                    columns={columns}
                    data={rows}
                    search
                    exportCSV >

                  {toolkitProps => (
                    <React.Fragment>
                        <Row className="mb-2">
                          <Col xl={searchActive ? "5" : "9" } sm="5">
                            <CardTitle className="h4">{title}</CardTitle>
                            <span className="mobile-view-csv">
                              <MyExportCSV { ...toolkitProps.csvProps } />
                            </span>
                          </Col>

                          {
                            isDate && 
                            <Col xl="2" sm="2" className="pull-right desktop-view-csv">
                              <input className="form-control" type="date" onChange={(e) => changeDate(e.target.value)} defaultValue={new Date().toISOString().substring(0, 10)} />
                            </Col>
                          }
                          
                          <Col xl="2"  sm="2" className="pull-right desktop-view-csv">
                            <MyExportCSV { ...toolkitProps.csvProps }/>
                          </Col>

                          {
                            searchActive && 
                            <Col xl={isAdd ? "4" : "3"} sm="3">
                              <div className="search-box me-2 mb-2 d-inline-block" style={{width: "100%"}}>
                                <div className="position-relative">
                                  <SearchBar 
                                    {...toolkitProps.searchProps}
                                  />
                                  <i className="bx bx-search-alt search-icon" />
                                </div>
                              </div>
                            </Col>
                          }
                          {
                            isAdd &&
                            <Col xl="1" sm="2">
                              <Button
                                color="primary"
                                className=""
                                onClick={handdleAddButton}
                              >
                                Add
                              </Button>
                            </Col>
                          }
                        </Row>
                        <Row>
                        <Col xl="12">
                          <div className="table-responsive">
                            <BootstrapTable
                              keyField={"id"}
                              responsive
                              bordered={false}
                              striped={false}
                              defaultSorted={defaultSorted}
                              classes={
                                "table align-middle table-nowrap"
                              }
                              headerWrapperClasses={"thead-light"}
                              {...toolkitProps.baseProps}
                              {...paginationTableProps}
                            />
                          </div>
                        </Col>
                      </Row>

                      <Row className="align-items-md-center mt-30">
                        <Col className="inner-custom-pagination d-flex">
                          <div className="d-inline">
                            <SizePerPageDropdownStandalone
                              {...paginationProps}
                            />
                          </div>
                          <div className="text-md-right ms-auto">
                            <PaginationListStandalone
                              {...paginationProps}
                            />
                          </div>
                        </Col>
                      </Row>
                    </React.Fragment>
                  )
                  }
                </ToolkitProvider>
                
              )
              }</PaginationProvider>

            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default Datatables
