import { GET_CLIENTS, ADD_CLIENTS, GET_CLIENTS_SUCCESS, ADD_CLIENTS_SUCCESS } from './actionTypes';

const INIT_STATE = {
    clients: [],
    loading: false,
}
  
const ClientsR = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_CLIENTS:
            return {
                ...state,
                loading: true
            }
        case GET_CLIENTS_SUCCESS:
            return {
                ...state,
                loading: false,
                clients: action.payload.data
            }
        case ADD_CLIENTS:
            return {
                ...state,
                loading: true
            }
        case ADD_CLIENTS_SUCCESS:
            return {
                ...state,
                loading: false
            }
        default:
            return state
    }
}

export default ClientsR