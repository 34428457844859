import Drawer from 'react-modern-drawer'
import 'react-modern-drawer/dist/index.css'

import Dropzone from 'components/Dropzone'
import { AvForm, AvField } from "availity-reactstrap-validation"

const Form = ({ Position, isOpen, onDrawerClose, title, width = 30, submitForm, editableObj, allOption}) => {

    //FormFileds
    let fileds = [
        {
            name: "firstname",
            label: "First Name",
            placeholder: "Please Enter First Name",
            required: true,
            type: 'text',
            value: editableObj?editableObj.first_name : '',
            col:4
        },{
            name: "middlename",
            label: "Middle Name",
            placeholder: "Please Enter Middle Name",
            required: false,
            type: 'text',
            value: editableObj?editableObj.middle_name:'',
            col: 4
        },{
            name: "lastname",
            label: "Last Name",
            placeholder: "Please Enter Last Name",
            required: true,
            type: 'text',
            value: editableObj?editableObj.last_name : '',
            col:4
        },{
            name: "gender",
            label: "Gender",
            placeholder: "Please Select Gender",
            required: true,
            type: 'select',
            value: editableObj?editableObj.gender : '',
            col:4,
            options: [
                {"code":1, "title":"Male"},
                {"code":2, "title":"Female"},
            ]
        },{
            name: "code",
            label: "Employee Code",
            placeholder: "Please Enter Employee Code",
            required: true,
            type: 'text',
            value: editableObj?editableObj.code : '',
            col:4
        },{
            name: "mobile",
            label: "Mobile Number",
            placeholder: "Please Enter Mobile Number",
            required: false,
            type: 'text',
            value: editableObj?editableObj.mobile : '',
            col:4
        },{
            name: "email",
            label: "Email Address",
            placeholder: "Please Enter Email Address",
            required: false,
            type: 'text',
            value: editableObj?editableObj.email : '',
            col:4
        },{
            name: "designation",
            label: "Designation",
            placeholder: "Please Select Employee Designation",
            required: false,
            type: 'select',
            value: editableObj?editableObj.designation : '',
            col:4,
            options: allOption?.designations
        },{
            name: "location",
            label: "Location",
            placeholder: "Please Select Employee Location",
            required: false,
            type: 'select',
            value: editableObj?editableObj.location : '',
            col:4,
            options: allOption?.locations
        },{
            name: "id",
            required: false,
            type: 'hidden',
            value: editableObj?editableObj.id : ''
        }
    ]
    
    return (
        <Drawer
            open={isOpen}
            onClose={onDrawerClose}
            direction={Position}
            style={{
                width: `${width}%`
            }}
        >
            <>
                <div className="rightbar-title px-3 py-4">
                    <span className="right-bar-toggle float-end cursor-pointer" title="close" onClick={onDrawerClose}><i className="mdi mdi-close noti-icon"></i></span>
                    <h5 className="m-0">{title}</h5>
                </div>
                <hr className="my-0"></hr>
                <div className='col-md-12' style={{padding: '15px', height: '90vh', overflow: 'auto'}}>
                    <AvForm
                        className="form-horizontal"
                        onLoad={e => formik.resetForm()}
                        onValidSubmit={(e, v) => {
                            submitForm(e, v)
                        }}
                    >
                        <div className='row'>
                            <div className="col-md-12 mb-3">
                                <div className='row'>
                                    {
                                        fileds.map((v, i) => (
                                            // eslint-disable-next-line react/jsx-key
                                            <div className={`col-md-${v.col} mb-3`} key={`${i}_div`}>
                                                {
                                                    v.type == 'select' ? 
                                                    <AvField type={v.type} name={v.name} label={v.label} helpMessage={v.placeholder}>
                                                        {
                                                            v.options?.map( (o, i) => (
                                                                <option key={`o_${i}`} value={o.code}>{o.title}</option>
                                                            ))
                                                        }
                                                    </AvField>
                                                    :<AvField
                                                        key={`${i}_input`}
                                                        name={v.name}
                                                        label={v.label}
                                                        className="form-control"
                                                        placeholder={v.placeholder}
                                                        type={v.type}
                                                        value={v.value}
                                                        required={v.required}
                                                        />
                                                }
                                            </div>)
                                        ) 
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="mt-3 d-grid">
                            <button
                                className="btn btn-primary btn-block "
                                type="submit"
                            >Submit</button>
                            {/* >{isType == false ? 'Submit' : 'Update'}</button> */}
                        </div>
                    </AvForm>
                </div>
            </>
        </Drawer>
    );
}

export default Form;