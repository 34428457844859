import { GET_ATTENDANCE, GET_ATTENDANCE_SUCCESS, GET_VISITS, GET_VISITS_SUCCESS } from "./actionType";

const INIT_STATE = {
    attendance: [],
    visits: [],
    loading: false,
}
  
const AttendanceR = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_ATTENDANCE:
            return {
                ...state,
                loading: true
            }
        case GET_ATTENDANCE_SUCCESS:
            return {
                ...state,
                loading: false,
                attendance: action.payload.data
            }
        case GET_VISITS:
            return {
                ...state,
                loading: true
            }
        case GET_VISITS_SUCCESS:
            return {
                ...state,
                loading: false,
                visits: action.payload.data
            }
        default:
            return state
    }
}

export default AttendanceR