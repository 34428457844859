import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes"
import { apiError, loginSuccess, logoutUserSuccess } from "./actions"

//Include Both Helper File with needed methods
import {
  LoginUserAPI,
} from "helpers/api_call_service"

function* loginUser({ payload: { user, history } }) {
  try {
    const res = yield call(LoginUserAPI, {
      username: user.username,
      password: user.password,
      client_code: user.client_code,
      isType: true,
    })

    if(res?.data?.token){
      localStorage.setItem("token", res.data.token)
      localStorage.setItem("authUser", JSON.stringify(res.data))
      yield put(loginSuccess(res.data))
      
      if(localStorage.getItem('client_code') == 'KSL000'){
        history.push("/clients")
      }else{
        history.push("/dashboard")
      }
    }
  } catch (error) {
    yield put(apiError(error))
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("token")
    localStorage.removeItem("authUser")
    history.push("/login")
  } catch (error) {
    yield put(apiError(error))
  }
}


function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
