import axios from "axios"
import { del, get, post, put } from "./api_helper"
import * as url from "./url_helper"

const LoginUserAPI = data => post(url.POST_LOGIN, data)


export {
    LoginUserAPI
}
  