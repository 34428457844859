import axios from "axios"
import { API_URL } from "./url_helper"

const axiosApi = axios.create({
  baseURL: API_URL,
})


axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
  )
  
export async function get(url, config = {}) {
  axiosApi.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem('token')}`
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export async function post(url, data, config = {}) {
  axiosApi.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem('token')}`
  return axiosApi
  .post(url, { ...data }, { ...config })
  .then(response => response.data)
}

export async function put(url, data, config = {}) {
  axiosApi.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem('token')}`
  return axiosApi
  .put(url, { ...data }, { ...config })
  .then(response => response.data)
}

export async function del(url, config = {}) {
  axiosApi.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem('token')}`
  return await axiosApi
    .get(url, { ...config })
    .then(response => response.data)
}
