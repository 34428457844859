import React from "react"
import { Redirect } from "react-router-dom"


// Authentication related pages
import Login from "../modules/Authentication/Login"

// Dashboard
import Dashboard from "../modules/Dashboard/index"
import Department from "../modules/Master/Department"
import Designation from "../modules/Master/Designation"
import Locations from "../modules/Master/Locations"
import Employees from "../modules/Employee/employees"
import Attendance from "../modules/Attendance"
import Punches from "../modules/Punches"
import Clients from "../modules/admin/clients"
import Subcriptions from "../modules/admin/subcription"
import Logs from "../modules/Logs"
import DaywisePunchesReport from "modules/Reports/daywiseReports"

//Pages
import PagesMaintenance from "../modules/Utility/pages-maintenance"
import PagesComingsoon from "../modules/Utility/pages-comingsoon"
import Pages404 from "../modules/Utility/pages-404"
import Pages500 from "../modules/Utility/pages-500"

const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/department", component: Department },
  { path: "/designation", component: Designation },
  { path: "/locations", component: Locations },
  { path: "/employees", component: Employees },
  { path: "/attendance", component: Attendance },
  { path: "/attendance", component: Attendance },
  { path: "/visits", component: Punches },
  { path: "/clients", component: Clients },
  { path: "/subcriptions", component: Subcriptions },
  { path: "/daywise-punches-report", component: DaywisePunchesReport },
  { path: "/logs", component: Logs },
  { path: "/logout", component: () => {
      localStorage.clear();
      window.location.href = '/';
    }
  },

  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },  
]

const publicRoutes = [
  { path: "/login", component: Login },
  { path: "/pages-maintenance", component: PagesMaintenance },
  { path: "/pages-comingsoon", component: PagesComingsoon },
  { path: "/pages-404", component: Pages404 },
  { path: "/pages-500", component: Pages500 },
]

export { authProtectedRoutes, publicRoutes }
