import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
    Container,
    Row,
    Col
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import Datatable from "components/Datatables/datatable"
import { get } from 'helpers/api_helper'
import { getWeekNameByDate } from 'helpers/basic_helper'

const DaywisePunchesReports = () => {
    const [dataRows, setDataRows] = useState(null);
    
    useEffect(async() => {
        const res = await get(`/reports/daywisepunches`);
        if(res.status_code == 200){
            setDataRows(res.rows)
        }
    },[])
    

    const columns_heading = [
        {
            dataField: 'id',
            text: '#',
            sort: true,
        },{
            dataField: 'date',
            text: 'Day',
            sort: true,
        },{
            dataField: 'date',
            text: 'WeekDay',
            formatter: (cell, row,  rowIndex, extraData) => getWeekNameByDate(row.date)
        },{
            dataField: 'employees',
            text: 'Employee',
            sort: true,
            formatter: (cell, row,  rowIndex, extraData) => (parseInt(row.employees) > 9) ? <b>{row.employees}</b> : row.employees
        },{
            dataField: 'count',
            text: 'Punches',
            sort: true,
            formatter: (cell, row,  rowIndex, extraData) => (row.count > 99) ? <b>{row.count}</b> : row.count
        }
    ];

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Dashboard | Daywise punches</title>
                </MetaTags>
                <Container fluid>
                    <Breadcrumbs
                        title="Reports"
                        breadcrumbItem={"Daywise Punches"}
                    />

                    <Row>
                        <Col xl="12">
                            <Datatable 
                                title={`Day Wise Punches`}
                                columns={columns_heading}
                                rows={dataRows || []}
                                isAdd={false}
                                isDate={false}
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default DaywisePunchesReports;