import moment from 'moment';

export const getTime = (value) => moment(value).format("hh:mm:ss A");
export const getDate = (formate = "DD/MMM/yyyy") => moment().format(formate);

export const getWeekNameByDate = (givenDate) => {
    const date = new Date(givenDate);
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const dayIndex = date.getDay();
    return dayIndex == 0 ? <b>{daysOfWeek[dayIndex]}</b> : daysOfWeek[dayIndex];
}