import { GET_VISITES, GET_VISITES_SUCCESS } from './actionType'

export const getVists = (data, history = false, cb = false) => ({
    type: GET_VISITES,
    payload: {data, history, cb},
})

export const getVistsSuccess = payload => ({
    type: GET_VISITES_SUCCESS,
    payload: payload,
})

