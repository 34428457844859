import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody } from "reactstrap"
import { Line } from 'react-chartjs-2';
import { get } from 'helpers/api_helper'
import { DASHBOARD_GRAPH_DATA } from 'helpers/url_helper'

const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'right',
      },
      title: {
        display: true,
        text: 'Visits & Attendance Chart',
      },
    },
};
const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'Aguest', 'September', 'October', 'November', 'December'];

const Graph = () => {
    const [data, setData] = useState(false)
    useEffect(async() => {
        const res = await get(DASHBOARD_GRAPH_DATA);
        if(res.success){
            const visits = res.data.visits;
            setData({
                labels,
                datasets: [{
                    fill: false,
                    data: labels.map((v,i) => {
                        const data = visits.filter( x => x.month == i+1)
                        return data.length > 0 ? data[0].total : 0
                    }),
                    label: 'Visits',
                    borderColor: '#8f96a3'
                  }]
            })
        }
    },[])

    return (
        <Card>
            <CardBody>
                <Row>
                    <Col>
                        <h5>Attendance and Visits By current year</h5>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        {
                            data &&    
                            <Line
                                options={options}
                                data={data}
                                height={180}
                            />
                        }
                    </Col>
                </Row>
            </CardBody>
        </Card>
    )
}

export default Graph