import { call, takeEvery, put } from "redux-saga/effects"
import { del, get, post, put as upput } from "helpers/api_helper"

import {
    ADD_RECORD,
    GET_ALL_ROWS,
    REMOVE_RECORD
} from './actionType'

import {
    getAllRowsSuccess,
    updateRecord,
    pushNewRecord
} from "./actions"

const getAllRows = ({url}) => get(url)
const addCall = ({url, Obj}) => post(url, Obj)
const updateCall = ({url, Obj}) => upput(url, Obj)
const removeCall = ({url}) => del(url)

function* actionGetAllRows({ payload: { data, history, cb } }) {
    try {
        const res = yield call(getAllRows, data)
        if(res?.response){  
            if(data.isSingle){
                yield call(cb, res.response[0])
            }else{
                yield put(getAllRowsSuccess({data:res.response, isType:data.isType}))
            }
        }
    } catch (error) {
        console.log(error)
    }
}

function* actionAddRecord({payload: {data, history, addupdate_callback}}){
    try{
        const res = data?.id ? yield call(updateCall, data) : yield call(addCall, data)
        if(res?.status == 200){
            yield call(addupdate_callback)
            data?.id ? yield put(updateRecord({isType: data.isType, data:res.response, isUpdate: true})): yield put(pushNewRecord({isType: data.isType, data:res.response}))
        }
    }catch(error){
        console.log(error)
    }
}

function* actionRemoveRecord({payload: {data, cb}}){
    try{
        const res = yield call(removeCall, data)
        if(res.status == 200){
            yield call(cb, data)
            yield put(updateRecord({data:data, isType:data.isType}))
        }
    }catch(error){
        console.log(error)
    }
}

function* MasterSaga() {
    yield takeEvery(GET_ALL_ROWS, actionGetAllRows)
    yield takeEvery(ADD_RECORD, actionAddRecord)
    yield takeEvery(REMOVE_RECORD, actionRemoveRecord)
}
  
export default MasterSaga
  