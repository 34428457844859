import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
    Container,
    Row,
    Col
} from "reactstrap"
import { Link } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

import Datatable from "components/Datatables/datatable"
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import { MASTER_URL } from "helpers/url_helper"

import FormComponent from "../FormComponent";

import { getAllRows, addRecord, removeRecord } from 'store/actions';

const Master_URL = `${MASTER_URL}${process.env.REACT_APP_DESIGNATION}`
const isType = 'designation'

const Designation = props => {
    const dispatch = useDispatch()
    const { loading, designations } = useSelector(state => state.Master);
    const [open, isOpen] = useState(false)
    const [editableObj, setEditableObj] = useState({})
    const [dataRows, setDataRows] = useState([])

    const cb = (data) => {
        setEditableObj(e=> data)
        isOpen( e => !open)
    }

    useEffect(() => {
        setDataRows(curr => designations)
    },[designations])

    useEffect(() => {
        dispatch(getAllRows({url:Master_URL, isSingle: false, isType: isType}, props.history))
    },[])
    
    const handdleAddButton = () => {
        isOpen( e => !open)
    }

    const addupdate_callback = () => {
        isOpen( e => !open)
    }

    // Open RightSide Panel
    const submitForm = (data) => {
        const Obj = {
            "title": data.target.title.value,
            "code": data.target.code.value
        }
        
        dispatch(addRecord({isType:isType, Obj:Obj, url: Master_URL+`${data?.target?.id?.value ? '&q='+data?.target?.id?.value : '' }`, id: data?.target?.id?.value}, props.history, addupdate_callback))
    }

    // Update Data , Get Singlw ROw using url params ?q=?
    const handleEdit = data => {
        if(data > 0) dispatch(getAllRows({url:`${Master_URL}&q=${data}`, isSingle: true}, props.history, cb))
    }

    // Remove Data
    const handleRemove = (data, status) => {
        const cbr = (v) => {}
        if(data > 0) dispatch(removeRecord({url:`${Master_URL}&q=${data}&status=${status}`, data: {id:data, status:status}, isType: isType}, cbr))
    }

    // table Column Data
    let company_columns = [
        {
            dataField: 'title',
            text: 'Designation',
            sort: true
        }, {
            dataField: 'code',
            text: 'Code',
            sort: true
        }, {
            dataField: 'actions',
            text: "Actions",
            isDummyField: true,
            formatExtraData: loading,
            formatter: (cell, row,  rowIndex, extraData) => {
                return <>
                    <Link
                    to="#"
                    className="btn btn-outline-secondary btn-sm edit"
                    onClick={() => handleEdit(row.id)} 
                    title="Edit" >
                    <i className="fas fa-pencil-alt"></i>
                    </Link>
                    {" "}
                    <Link
                    to="#"
                    className={`btn btn-outline-${row.status == 0 ? "danger" : "success"} btn-sm edit`}
                    onClick={() => handleRemove(row.id, row.status == 1? 0 : 1)} 
                    title={row.status == 0 ?'De-active' : 'Active'} >
                    <i className={`fas fa-${row.status == 0 ? 'trash-alt' : 'check'}`}></i>
                    </Link>
                </>
            }
        }
    ];

    //FormFileds
    let formFileds = [
        {
            name: "id",
            required: false,
            type: 'hidden',
            value: editableObj?editableObj.id : '',
            col:12
        },{
            name: "title",
            label: "Designation",
            placeholder: "Designation Title",
            required: true,
            type: 'text',
            value: editableObj?editableObj.title : '',
            col:12
        },{
            name: "code",
            label: "Code",
            placeholder: "Code",
            required: true,
            type: 'text',
            value: editableObj?editableObj.code:'',
            col: 12
        }
    ]

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Dashboard | {isType}</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumbs
                        title="Masters"
                        breadcrumbItem={isType}
                    />

                    <Row>
                        <Col xl="12">
                            <Datatable 
                                title={`All ${isType}`}
                                handdleAddButton={handdleAddButton}
                                columns={company_columns}
                                rows={dataRows}
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
            
            <FormComponent
                title={`${editableObj?.length ? 'Edit' : 'Add'} Designation`}
                Position={"right"}
                isOpen={open}
                fileds={formFileds}
                editableObj={editableObj[0]}
                onDrawerClose={(e) => {
                    isOpen(e => false)
                    setEditableObj(e => [])
                }}
                submitForm={submitForm}
            />

        </React.Fragment>
    )
}

Designation.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(Designation)
