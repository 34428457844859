import { call, takeEvery, put } from "redux-saga/effects"
import { del, get, post, put as upput } from "helpers/api_helper"

import {
    GET_ATTENDANCE_COUNT
} from './actionType'

import {
    getAttendanceCountSuccess
} from "./actions"

const getAllRows = url => get(url)

function* actionDashboardData({payload: {url}}) {
    try {
      const res = yield call(getAllRows, url)
      if(res?.data){
        yield put(getAttendanceCountSuccess(res.data))
      }
    } catch (error) {
        console.log(error)
    }
}


function* DashboardSaga() {
    yield takeEvery(GET_ATTENDANCE_COUNT, actionDashboardData)
}
  
export default DashboardSaga
  