import React, {useEffect, useState}from "react"
import MetaTags from 'react-meta-tags';
import { Link } from "react-router-dom"
import { Col, Container, Row} from "reactstrap"

import { AvForm, AvField } from "availity-reactstrap-validation"
import { get} from "helpers/api_helper"
// actions
import { loginUser } from "../../store/actions"

// import images
import logodark from "../../assets/images/logo-light.png"
import logolight from "../../assets/images/logo-light.png"
import bg from "../../assets/images/bg.png"
import { useDispatch } from 'react-redux';
import { API_URL } from "helpers/url_helper"

const style = {
  MozTransform: 'scale(-1, -1)',
  OTransform: 'scale(-1, -1)',
  webkiTransform: 'scale(-1, -1)',
  transform: 'scale(-1, -1)',
  position: 'absolute',
  width: '280px',
  top: '0',
  right:0
}

const Login = props => {
  const dispatch = useDispatch()

  const [logo, setLogo] = useState('')
  const [loader, setLoder] = useState(true)

  useEffect(async () => {
    const res = await get(`/clients`)
    Object.keys(res.data).map(v => {
      localStorage.setItem(v, res.data[v])
    })
    setLogo(`${API_URL}${localStorage.getItem('client_logo')}`)
  }, [])

  useEffect(() => {
    setLoder(false)
  }, [logo])

  const submitLogin = (e, v) => {
    // eslint-disable-next-line react/prop-types
    dispatch(loginUser(v, props.history))
  }
  
  return (
    <React.Fragment>
      <div>
        <MetaTags>
          <title>keepSmile Dental Lab | Login</title>
        </MetaTags>
        <Container fluid className="p-0">
          {
            loader && <span>Loading</span>
          }
          {
            !loader && 
            <Row className="g-0" style={{height: '100%', padding: '150px 0px'}}>
              {/* <CarouselPage /> */}

              <img src={bg} style={style} />
              <Col xl={3} style={{zIndex: 9999, height: '560px', width:'360px', margin: '0 auto', borderRadius: '26px', border: 'gray 2px solid'}} >
                <div className="p-md-5 p-4">
                  <div className="w-100">
                    <div className="d-flex flex-column">
                      <div className="mb-4 mb-md-5">
                        <Link to="/dashboard" className="d-block auth-logo">
                          {
                            logo ? 
                            <img
                            src={logo}
                            alt=""
                            height="60"
                            className="auth-logo-dark"
                          /> :
                          <>
                            <img
                              src={logodark}
                              alt=""
                              height="60"
                              className="auth-logo-dark"
                            />
                            <img
                              src={logolight}
                              alt=""
                              height="18"
                              className="auth-logo-light"
                            />
                          </>
                          }
                        </Link>
                      </div>
                      <div className="my-auto">
                        <div>
                          <h5 className="text-primary">Welcome Back !</h5>
                          <p className="text-muted">
                            Sign in to continue to Dashboard.
                          </p>
                        </div>

                        <div className="mt-4">
                          <AvForm
                            className="form-horizontal"
                            onValidSubmit={(e, v) => {
                              submitLogin(e, v)
                            }}
                          >
                            <div className="mb-3">
                            <AvField
                                name="username"
                                label="Username or Email"
                                className="form-control"
                                placeholder="Enter username or email"
                                type="text"
                                value=""
                                required
                              />
                              <AvField
                                name="client_code"
                                type="hidden"
                                value={localStorage.getItem('client_code')}
                                required
                              />
                            </div>
                            <div className="mb-3">
                              {/* <div className="float-end">
                                <Link
                                  to="auth-recoverpw-2"
                                  className="text-muted"
                                >
                                  Forgot password?
                                </Link>
                              </div> */}
                              <AvField
                                name="password"
                                label="Password"
                                className="form-control"
                                placeholder="Enter password"
                                type="password"
                                value=""
                                required
                              />
                            </div>

                            <div className="mt-3 d-grid">
                              <button
                                className="btn btn-primary btn-block "
                                type="submit"
                              >Log In</button>
                            </div>
                          </AvForm>
                        </div>
                      </div>

                      <div className="mt-4 mt-md-5 text-center">
                        <p className="mb-0">
                          <img src={logodark} width="90" className="mb-2"/> <br /> By logging in, you agree to Keepsmile Dental Lab Terms of Use and Privacy Policy
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <img src={bg} style={{ position: 'absolute', width: '480px', bottom: '0'}} />
            </Row>
          }
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Login
