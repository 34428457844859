import { GET_CLIENTS, ADD_CLIENTS, GET_CLIENTS_SUCCESS, ADD_CLIENTS_SUCCESS } from './actionTypes';

export const getClients = (data) => ({
    type: GET_CLIENTS,
    payload: {data}
});

export const getClientsSuccess = (data) => ({
    type: GET_CLIENTS_SUCCESS,
    payload: {data}
});


export const addClients = (data) => ({
    type: ADD_CLIENTS,
    payload: {data}
});

export const addClientsSuccess = (data) => ({
    type: ADD_CLIENTS_SUCCESS,
    payload: {data}
});
