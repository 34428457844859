import { all, fork } from "redux-saga/effects"

//public
import AuthSaga from "./auth/login/saga"
import LayoutSaga from "./layout/saga"


// Company
import MasterSaga from 'store/master/saga';

// Employee
import EmployeeSaga from "modules/Employee/store/saga";
import AttendanceSaga from "modules/Attendance/store/saga";
import VisitsSaga from "modules/Punches/store/saga";

import DashboardSaga from "modules/Dashboard/store/saga";

import ClientsSaga from "modules/admin/store/saga";

export default function* rootSaga() {
  yield all([
    //public
    fork(AuthSaga),
    fork(LayoutSaga),
    fork(MasterSaga),
    fork(EmployeeSaga),
    fork(AttendanceSaga),
    fork(VisitsSaga),
    fork(DashboardSaga),
    fork(ClientsSaga)
  ])
}
