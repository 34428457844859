import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"
import { Link } from "react-router-dom"

import {
    GET_ATTENDANCE_COUNT_URL
} from "helpers/url_helper"

import { getAttendanceCount } from 'store/actions';

import ApexRadial from "./ApexRedail"
import { useDispatch, useSelector } from 'react-redux';

const Attendance = () => {
    const dispatch = useDispatch()
    const { loading, dashbaord } = useSelector(state => state.Dashbaord);
    const [today, setToday] = useState(0);
    const [preToday, setPreToday] = useState(0);
    const [present, setPresent] = useState(0);

    useEffect(() => {
        dispatch(getAttendanceCount({url:GET_ATTENDANCE_COUNT_URL}));
    }, []);

    useEffect(() => {
        if(dashbaord.visits > 0)
            setToday(dashbaord.visits)
        
        if(dashbaord.prev_visits > 0)
            setPreToday(dashbaord.prev_visits)
    
        if(dashbaord.present > 0)
            setPresent(dashbaord.present)
    }, [dashbaord])

    return (
        <React.Fragment>
        {" "}
        <Card>
            <CardBody>
            <Row>
                <Col sm="8">
                    <CardTitle className="mb-4">Today Visits</CardTitle>
                    <h3>{today || 0}</h3>
                    <p className="text-muted">
                        <span className={`text-${preToday > today ? 'danger':'success'} me-2`}>
                        {" "}
                        { today ? Math.abs((((preToday-today)/preToday)*100)).toFixed(2) : 0 }% <i className={`mdi mdi-arrow-${preToday > today ? 'down':'up'}`}></i>{" "}
                        </span>{" "}
                        From previous day visits
                    </p>
                    <div className="mt-4">
                        <Link
                        to="visits"
                        className="btn btn-primary  btn-sm"
                        >
                        View More <i className="mdi mdi-arrow-right ms-1"></i>
                        </Link>
                    </div>
                </Col>
                <Col sm="4" style={{ position: 'absolute', top: 0, right: 0 }}>
                    <div className="mt-4 mt-sm-0">
                        {
                            present > 0 &&
                            <ApexRadial countValue={present}/>
                        }
                    </div>
                </Col>
            </Row>
            </CardBody>
        </Card>
        </React.Fragment>
    )
}

export default Attendance
