import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
    Container,
    Row,
    Col
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

import Datatable from "components/Datatables/datatable"
import { useDispatch, useSelector } from 'react-redux';

import { GET_PUNCHES_URL } from "helpers/url_helper"

import { getVists } from 'store/actions';

const Logs = props => {
    const dispatch = useDispatch()
    const { loading, visits } = useSelector(state => state.Visits);
    const [dataRows, setDataRows] = useState([])
    const [lat, setLat] = useState("")
    const [modalMap, setModalMap] = useState(false)
    const [points, setPoints] = useState([])
        
    useEffect(() => {
        dispatch(getVists({url:`${GET_PUNCHES_URL}`, isSingle: false}, props.history))
    },[])

    useEffect(() => {
        setDataRows(() => visits)
    }, [visits])
    
    // table Column Data
    let company_columns = [
        {
            dataField: 'name',
            text: 'Employee',
            sort: true,
            formatter: (cell, row,  rowIndex, extraData) => <span style={{textTransform: 'uppercase'}}>{row.name}</span>
        },{
            dataField: 'code',
            text: 'Employee Code',
            sort: true,
            formatter: (cell, row,  rowIndex, extraData) => <span style={{textTransform: 'uppercase', fontWeight: 'bold'}}>{row.code}</span>
        },{
            dataField: 'in_date',
            text: 'Date',
            sort: true
        },{
            dataField: 'type',
            text: 'visiting Type',
            sort: true,
            formatter: (cell, row,  rowIndex, extraData) => {
                const types = {"1":"visit", "2": "pickup", "3": "delivery"};
                return <span style={{textTransform: 'uppercase', fontWeight: 'bold'}}>{types[row.type]}</span>
            }
        },{
            dataField: 'client',
            text: 'Client',
            sort: true,
            formatter: (cell, row,  rowIndex, extraData) => <span style={{textTransform: 'uppercase', fontWeight: 'bold'}}>{row.client}</span>
        },{
            dataField: '_in',
            text: 'IN',
            sort: true,
            formatter: (cell, row,  rowIndex, extraData) =>
                <div onClick= {()=> getLatlong(row.in_lat_long)}>
                    <span style={{fontWeight: 'bold'}}>{row.in_time} {" "}</span> 
                    <i className="fas fa-map-marker-alt" style={{fontSize: '18px'}} ></i>
                </div>
        },{
            dataField: '_out',
            text: 'OUT',
            sort: true,
            formatter: (cell, row,  rowIndex, extraData) =>
                row.out_time != null ?
                    <div onClick= {()=> getLatlong(row.out_lat_long)} ><span style={{fontWeight: 'bold'}}>{row.out_time} {" "} </span> <i className="fas fa-map-marker-alt" style={{fontSize: '18px'}}></i></div>
                : "---"
        }
    ];

    const returnDate = async (data = false) => {
        const date = data ? data : getDate('yyyy-MM-DD')
        if(date){
            dispatch(getVists({url:`${GET_PUNCHES_URL}/all/${date}/${date}`, isSingle: false}, props.history))
        }
    }
    
    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    const getLatlong = (data) => {
        setPoints(c => data)
        setModalMap(true);
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Dashboard | user Logs</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumbs
                        title=""
                        breadcrumbItem={"Visits"}
                    />

                    <Row>
                        <Col xl="12">
                            <Datatable 
                                title={`All logs`}
                                columns={company_columns}
                                rows={dataRows}
                                isAdd={false}
                                isDate={false}
                                returnDate={returnDate}
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default (Logs)
