import {
    GET_EMPLOYEE_ALL_ROWS,
    GET_EMPLOYEE_SINGLE_ROW,
    GET_EMPLOYEE_ALL_ROWS_SUCCESS,
    ADD_EMPLOYEE_RECORD,
    ADD_EMPLOYEE_RECORD_SUCCESS,
    REMOVE_EMPLOYEE_RECORD,
    UPDATE_EMPLOYEE_RECORD,
    PUSH_EMPLOYEE_NEW_RECORD,
    GET_OPTIONS,
    GET_OPTIONS_SUCCESS,
    GET_EMPLOYEE_VISITS_ROWS,
    GET_EMPLOYEE_VISITS_ROWS_SUCCESS,
    RESET_DEVICE
} from './actionType'

const INIT_STATE = {
    employees: [],
    locations: [],
    designations: [],
    office_locations: [],
    visits: [],
    loading: false,
}
  
const EmployeeR = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_EMPLOYEE_ALL_ROWS:
        case GET_EMPLOYEE_VISITS_ROWS:
        case RESET_DEVICE:
        case ADD_EMPLOYEE_RECORD:
            return {
              ...state,
              loading: true,
              visits: []
            }
        case ADD_EMPLOYEE_RECORD_SUCCESS:
            return {
              ...state,
              loading: false,
            }
        case PUSH_EMPLOYEE_NEW_RECORD:
            return {
                ...state,
                loading: false,
                employees:[...state.employees, action.payload.data.data]
            }
        case GET_EMPLOYEE_ALL_ROWS_SUCCESS:
            return {
                ...state,
                loading: false,
                employees: action.payload.data
            }
        case GET_EMPLOYEE_VISITS_ROWS_SUCCESS:
            return {
                ...state,
                loading: false,
                visits: action.payload
            }
        case REMOVE_EMPLOYEE_RECORD:
            return {
                ...state,
                loading: true,
            }
        case GET_OPTIONS:
            return {
                ...state,
                loading: true,
            }
        case GET_OPTIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                locations: action.payload.data?.locations,
                designations: action.payload.data?.designations,
                office_locations: action.payload.data?.office_location, 
            }
        case UPDATE_EMPLOYEE_RECORD:
            return {
                ...state,
                loading: false,
                singleemployees : state.employees.map(item => {
                    if (!action.payload.isUpdate && item.id == action.payload.data.data.id) item.status = action.payload.data.data.status
                    if (action.payload.isUpdate && item.id == action.payload.data.id){
                        item.title = action.payload.data.title, 
                        item.code = action.payload.data.code, 
                        item.status = action.payload.data.status
                    }
                    return item;
                })
            }
        default:
            return state
    }
}

export default EmployeeR