import React, {useCallback} from 'react'
import {useDropzone} from 'react-dropzone'

const Dropzone = () => {
    const {acceptedFiles, getRootProps, getInputProps} = useDropzone();
  
    const files = acceptedFiles.map(file => (
      <li key={file.path}>
        {file.path} - {file.size} bytes
      </li>
    ));
  
    return (
      <>
        <div {...getRootProps({className: 'dropzone'})}>
          <input {...getInputProps()} />
          <p>Drag `n` drop some files here, or click to select files</p>
          {files}
        </div>
      </>
    );
}

export default Dropzone