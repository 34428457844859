import {
    GET_ALL_ROWS,
    GET_SINGLE_ROW,
    GET_ALL_ROWS_SUCCESS,
    ADD_RECORD,
    REMOVE_RECORD,
    UPDATE_RECORD,
    PUSH_NEW_RECORD
} from './actionType'

export const getAllRows = (data, history = false, cb = false) => ({
    type: GET_ALL_ROWS,
    payload: {data, history, cb},
})

export const pushNewRecord = (data) => ({
    type: PUSH_NEW_RECORD,
    payload: {data},
})

export const getAllRowsSuccess = payload => ({
    type: GET_ALL_ROWS_SUCCESS,
    payload: payload,
})

export const getSingleRowSuccess = payload => ({
    type: GET_SINGLE_ROW,
    payload: payload,
})

export const addRecord = (data, history, addupdate_callback) => ({
    type: ADD_RECORD,
    payload: {data, history, addupdate_callback}
})

export const addRecordSuccess = () => ({
    type: ADD_RECORD_SUCCESS,
    payload: {}
})

export const removeRecord = (data, cb) => ({
    type: REMOVE_RECORD,
    payload: {data,cb}
})

export const updateRecord = payload => ({
    type: UPDATE_RECORD,
    payload: payload
})
