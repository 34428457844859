import { call, takeEvery, put } from "redux-saga/effects"
import { del, get, post, put as upput } from "helpers/api_helper"

import {
    GET_CLIENTS,
    ADD_CLIENTS
} from './actionTypes'

import {
    getClientsSuccess,
    addClientsSuccess
} from "./actions"

const getAllRows = ({url}) => get(url)

function* actionClientsGetAllRows({ payload: { data } }) {
    try {
      const res = yield call(getAllRows, data)
        yield put(getClientsSuccess({data:res.data}))
    } catch (error) {
        console.log(error)
    }
}

function* actionClientsAddCall({ payload: { data } }) {
    try {
      const res = yield call(getAllRows, data)
        yield put(addClientsSuccess({data:res.data}))
    } catch (error) {
        console.log(error)
    }
}

function* AttendanceSaga() {
    yield takeEvery(GET_CLIENTS, actionClientsGetAllRows)
    yield takeEvery(ADD_CLIENTS, actionClientsAddCall)
}
  
export default AttendanceSaga
  