import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, Table } from "reactstrap"
import { get } from 'helpers/api_helper'
import { DASHBOARD_ATTENDANCE_LIST } from 'helpers/url_helper'
import { getTime, getDate } from "helpers/basic_helper"

const style = {
    table: {
      width: '100%',
      display: 'table',
      borderSpacing: 0,
      borderCollapse: 'separate',
    },
    th: {
      top: 0,
      left: 0,
      zIndex: 2,
      position: 'sticky',
      backgroundColor: '#fff',
    },
  };

const PrasentEmployeeList = () => {
    const [list, setList] = useState([])
    useEffect(async() => {
        const res = await get(DASHBOARD_ATTENDANCE_LIST);
        if(res.success){
            setList(res.data)
        }
    },[])

    return(
        <Card>
            <CardBody>
                <Row>
                    <Col>
                        <h5>Today Prasent Employee`s</h5>
                    </Col>
                    <Col style={{textAlign:'end'}}>
                        <h5>{getDate()}</h5>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <div style={{ maxHeight: '420px', overflowY: 'auto' }}>
                            <Table className="fixed-header" style={style.table} bordered>
                                <thead>
                                    <tr>
                                        <th style={style.th}>Employee Name</th>
                                        <th style={style.th}>IN-TIME</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        list.length ?
                                        list.map((v, i) =>
                                            <tr key={`DAL_${i}`}>
                                                <td>{v.employee_name}</td>
                                                <td>{getTime(v.INTIME)}</td>
                                            </tr>
                                        ) : <tr><td colSpan={2}>No Data Found</td></tr>
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    )
}

export default PrasentEmployeeList