import {
    GET_ALL_ROWS,
    GET_SINGLE_ROW,
    GET_ALL_ROWS_SUCCESS,
    ADD_RECORD,
    ADD_RECORD_SUCCESS,
    REMOVE_RECORD,
    EDIT_RECORD,
    UPDATE_RECORD,
    PUSH_NEW_RECORD
} from './actionType'

const INIT_STATE = {
    departments:[],
    designations:[],
    locations:[],
    loading: false,
}
  
const MasterR = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_ALL_ROWS:
        case ADD_RECORD:
            return {
              ...state,
              loading: true,
            }
        case ADD_RECORD_SUCCESS:
            return {
              ...state,
              loading: false,
            }
        case PUSH_NEW_RECORD:
            let newRecord = [];
            switch(action.payload.data.isType){
                case 'department':
                    newRecord = {departments: [...state.departments, action.payload.data.data]}
                    break
                case 'designation':
                    newRecord = {designations: [...state.designations, action.payload.data.data]}
                    break
                case 'locations':
                    newRecord = {locations: [...state.locations, action.payload.data.data]}
                    break
            }    
            return {
              ...state,
              loading: false,
              ...newRecord
            }
        case GET_ALL_ROWS_SUCCESS:
            let data = {};
            switch(action.payload.isType){
                case 'department':
                    data = {departments:  (typeof action.payload.data)  == 'string' ? [] : action.payload.data}
                    break
                case 'designation':
                    data = {designations:  (typeof action.payload.data)  == 'string' ? [] : action.payload.data}
                    break
                case 'locations':
                    data = {locations:  (typeof action.payload.data)  == 'string' ? [] : action.payload.data}
                    break
            }
            return {
                ...state,
                loading: false,
                ...data
            }
        case REMOVE_RECORD:
            return {
                ...state,
                loading: true,
            }
        case UPDATE_RECORD:
            let single;
            switch(action.payload.isType){
                case 'department':
                    single = state.departments.map(item => {
                        if (!action.payload.isUpdate && item.id == action.payload.data.data.id) item.status = action.payload.data.data.status
                        if (action.payload.isUpdate && item.id == action.payload.data.id){
                            item.title = action.payload.data.title, 
                            item.code = action.payload.data.code, 
                            item.status = action.payload.data.status
                        }
                        return item;
                    })
                case 'designation':
                    single = state.designations.map(item => {
                        if (!action.payload.isUpdate && item.id == action.payload.data.data.id) item.status = action.payload.data.data.status
                        if (action.payload.isUpdate && item.id == action.payload.data.id){
                            item.title = action.payload.data.title, 
                            item.code = action.payload.data.code, 
                            item.status = action.payload.data.status
                        }
                        return item;
                    })
                case 'locations':
                    single = state.locations.map(item => {
                        if (!action.payload.isUpdate && item.id == action.payload.data.data.id) item.status = action.payload.data.data.status
                        if (action.payload.isUpdate && item.id == action.payload.data.id){
                            item.title = action.payload.data.title, 
                            item.code = action.payload.data.code, 
                            item.status = action.payload.data.status
                        }
                        return item;
                    })
            }
            return {
                ...state,
                loading: false,
                ...single
            }
        default:
            return state
    }
}

export default MasterR