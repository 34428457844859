import { call, takeEvery, put } from "redux-saga/effects"
import { del, get, post, put as upput } from "helpers/api_helper"

import {
    GET_ATTENDANCE,
    GET_VISITS
} from './actionType'

import {
    getAttendanceSuccess,
    getVisitedLocationsSuccess
} from "./actions"

const getAllRows = ({url}) => get(url)

function* actionAttendanceGetAllRows({ payload: { data } }) {
    try {
      const res = yield call(getAllRows, data)
      yield put(getAttendanceSuccess({data:res.data}))
    } catch (error) {
        console.log(error)
    }
}

function* actionVisitsGetAllRows({ payload: { data, history, cb } }) {
    try {
      const res = yield call(getAllRows, data)
      if(res?.data){ 
        yield call(cb, true)
        yield put(getVisitedLocationsSuccess({data:res.data}))
      }
    } catch (error) {
        console.log(error)
    }
}

function* AttendanceSaga() {
    yield takeEvery(GET_ATTENDANCE, actionAttendanceGetAllRows)
    yield takeEvery(GET_VISITS, actionVisitsGetAllRows)
}
  
export default AttendanceSaga
  