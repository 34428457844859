import Dropzone from 'components/Dropzone'
import { AvForm, AvField } from "availity-reactstrap-validation"

const CustomForm = ({fileds, fileupload = false, isType = "add", submitForm}) => {
    return (
        <AvForm
            className="form-horizontal"
            onLoad={e => formik.resetForm()}
            onValidSubmit={(e, v) => {
                submitForm(e, v)
            }}
        >
            {
                fileupload && (
                    <div className="mb-3">
                        <Dropzone />
                    </div>
                )
            }
            <div className='row'>
                {
                    fileds.map((v, i) => (
                        // eslint-disable-next-line react/jsx-key
                        <div className={`col-md-${v.col} mb-3`} key={`${i}_div`}>
                            <AvField
                                key={`${i}_input`}
                                name={v.name}
                                label={v.label}
                                className="form-control"
                                placeholder={v.placeholder}
                                type={v.type}
                                value={v.value}
                                required={v.required}
                                />
                        </div>)
                    ) 
                }
            </div>

            <div className="mt-3 d-grid">
                <button
                    className="btn btn-primary btn-block "
                    type="submit"
                >{isType == false ? 'Submit' : 'Update'}</button>
            </div>
        </AvForm>
    )
}

export default CustomForm