// const { compose, withProps, lifecycle } = require("recompose");
// const {
//     withScriptjs,
//     withGoogleMap,
//     GoogleMap,
//     DirectionsRenderer,
//     Marker,
//     InfoWindow
// } = require("react-google-maps");

// const Map2 = compose(
//   withProps({
//     googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyB2Ae63UeEzyQZ4Ygosfq6_-4KdcyKQSFk&v=3.exp&libraries=geometry,drawing,places",
//     loadingElement: <div style={{ height: `100%` }} />,
//     containerElement: <div style={{ height: `400px` }} />,
//     mapElement: <div style={{ height: `100%` }} />,
//   }),
//   withScriptjs,
//   withGoogleMap,
//   lifecycle({
//     componentDidMount() {
//       const DirectionsService = new google.maps.DirectionsService();
//         DirectionsService.route({
//         origin: new google.maps.LatLng(41.8507300, -87.6512600),
//         destination: new google.maps.LatLng(41.8525800, -87.6514100),
//         travelMode: google.maps.TravelMode.DRIVING,
//       }, (result, status) => {
//         if (status === google.maps.DirectionsStatus.OK) {
//           this.setState({
//             directions: result,
//           });
//         } else {
//           console.error(`error fetching directions ${result}`);
//         }
//       });
//     }
//   })
// )(props =>
//   <GoogleMap
//     defaultZoom={14}
//     defaultCenter={new google.maps.LatLng(parseFloat(props.ping[0].co.lat), parseFloat(props.ping[0].co.lng))}
//   >
//     {props.ping.map( ( marker, i ) => {
//         return (
//             <Marker
//                 key={i}
//                 label={(i+1).toString()}
//                 position={{ lat: parseFloat(marker.co.lat), lng: parseFloat(marker.co.lng) }}
//             >
//                 <InfoWindow>
//                     <div>
//                         {marker.client}
//                     </div>
//                 </InfoWindow>
//             </Marker>
//         )
//     })}
//   </GoogleMap>
// );

import React, { useState, useEffect } from 'react';
import { ReactBingmaps } from 'react-bingmaps-plus';

const Map2 = ({ping}) => {
  const [pins, setPins] = useState([])
  useEffect(() => {
    ping.map( ( marker, i ) => {
      setPins(x => [...x, {
        "location":[parseFloat(marker.co.lat), parseFloat(marker.co.lng)],
        "addHandler":"mouseover",
        "infoboxOption": { title: 'Infobox Title', description: 'Infobox' },
        "pushPinOption":{ color: 'red', title: marker.client, description: marker.address }
      }])
    })
  },[ping])

  return (
    <div style={{height: '380px'}}>
      {
        pins.length > 0 &&
        <ReactBingmaps
          bingmapKey = "Ak9M-KzNsiCQ8ofIZK2iNgukDb73uexrl-IcBh3pYwzUVOtu8P-ndNvoQJDfmvH7"
          center = {[parseFloat(ping[0].co.lat), parseFloat(ping[0].co.lng)]} 
          pushPins  = {pins} 
          >
        </ReactBingmaps>
      }
    </div>
  )
}

export default Map2
