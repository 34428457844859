import PropTypes from "prop-types"
import React, { useEffect, useRef } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"

const SidebarContent = props => {
  const ref = useRef()
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false
    }
    scrollElement(item);
    return false
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
              <li>
                <Link to="/dashboard">
                  <i className="bx bx-home-circle"></i>
                  <span>{props.t("Dashboards")}</span>
                </Link>
              </li>
              {
                localStorage.getItem("client_code") == 'SEDEMO' &&
                <>
                  <li>
                    <Link to="/clients">
                      <i className="bx bx-user"></i>
                      <span>{props.t("Clients")}</span>
                    </Link>
                  </li>
                  {/* <li>
                    <Link to="/subcriptions">
                      <i className="bx bx-box"></i>
                      <span>{props.t("Subcriptions")}</span>
                    </Link>
                  </li> */}
                </>
              }
              <li className="menu-title">{props.t("Masters")}</li>
              <li>
                <Link to="/#" className="has-arrow ">
                  <i className="bx bx-layout"></i>
                  <span>{props.t("Masters")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="true">
                  {/* <li><Link to="/department">{props.t("Department")}</Link></li> */}
                  <li><Link to="/designation">{props.t("Designation")}</Link></li>
                  <li><Link to="/locations">{props.t("Locations")}</Link></li>
                </ul>
              </li>
              <li>
                <Link to="/employees">
                  <i className="bx bx-user"></i>
                  <span>{props.t("Employees")}</span>
                </Link>
              </li>
              <li className="menu-title">{props.t("Reports")} </li>
              <li>
                <Link to="/attendance">
                  <i className="fas fa-check-double"></i>
                  <span>{props.t("Attendance")}</span>
                </Link>
              </li>
              <li>
                <Link to="/visits">
                  <i className="bx bx-shape-circle"></i>
                  <span>{props.t("Visits")}</span>
                </Link>
              </li>
              <li>
                <Link to="/daywise-punches-report">
                  <i className="bx bx-grid"></i>
                  <span>{props.t("DayWise Punches")}</span>
                </Link>
              </li>
              <li className="menu-title">{props.t("userLogs")} </li>
              <li>
                <Link to="/logs">
                  <i className="bx bx-box"></i>
                  <span>{props.t("Logs")}</span>
                </Link>
              </li>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
