import { call, takeEvery, put } from "redux-saga/effects"
import { del, get, post, put as upput } from "helpers/api_helper"

import {
    GET_VISITES,
} from './actionType'

import {
    getVistsSuccess,
} from "./actions"

const getAllRows = ({url}) => get(url)

function* actionGetVisitsGetAllRows({ payload: { data, history, cb } }) {
    try {
      const res = yield call(getAllRows, data)
      if(res?.data){  
        if(data.isSingle){
            yield call(cb, res.response)
        }else{
            yield put(getVistsSuccess({data:res.data}))
        }
      }
    } catch (error) {
        console.log(error)
    }
}

function* VisitsSaga() {
    yield takeEvery(GET_VISITES, actionGetVisitsGetAllRows)
}
  
export default VisitsSaga
  