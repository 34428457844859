import { call, takeEvery, put } from "redux-saga/effects"
import { del, get, post, put as upput } from "helpers/api_helper"

import {
    ADD_EMPLOYEE_RECORD,
    GET_EMPLOYEE_ALL_ROWS,
    GET_OPTIONS,
    REMOVE_EMPLOYEE_RECORD,
    GET_EMPLOYEE_VISITS_ROWS,
    RESET_DEVICE
} from './actionType'

import {
    getEmployeeAllRowsSuccess,
    updateEmployeeRecord,
    pusEmployeehNewRecord,
    getAllOptionsSuccess,
    getEmployeeVisitRowsSuccess
} from "./actions"

const getCall = ({url}) => get(url)
const getAllOptionsCall = ({url}) => get(url)
const addCall = ({url, Obj}) => post(url, Obj)
const updateCall = ({url, Obj}) => post(url, Obj)
const removeCall = ({url}) => del(url)
const visitsCall = ({url, data}) => post(url, data)


function* actionEmployeeGetAllRows({ payload: { data, history, cb } }) {
    try {
      const res = yield call(getCall, data)
      if(res?.response){
        if(data.isSingle){
            yield call(cb, res.response)
        }else{
            yield put(getEmployeeAllRowsSuccess({data:res.response, isType:data.isType}))
        }
      }
    } catch (error) {
        console.log(error)
    }
}

function* getAllOptions({ payload: { data, history, cb } }) {
    try {
      const res = yield call(getAllOptionsCall, data)
      if(res?.response){
        if(data.isSingle){
            yield call(cb, res.response)
        }else{
            yield put(getAllOptionsSuccess({data:res.response, isType:data.isType}))
        }
      }
    } catch (error) {
        console.log(error)
    }
}

function* actionEmployeeAddRecord({payload: {data, history, addupdate_callback}}){
    try{
        const res = data?.id ? yield call(updateCall, data) : yield call(addCall, data)
        if(res?.status == 200){
            yield call(addupdate_callback)
            data?.id ? yield put(updateEmployeeRecord({isType: data.isType, data:res.response, isUpdate: true})): yield put(pushEmployeeNewRecord({isType: data.isType, data:res.response}))
        }
    }catch(error){
        console.log(error)
    }
}

function* actionEmployeeRemoveRecord({payload: {data, cb}}){
    try{
        const res = yield call(removeCall, data)
        if(res.status == 200){
            yield call(cb, data)
            yield put(updateEmployeeRecord({data:data, isType:data.isType}))
        }
    }catch(error){
        console.log(error)
    }
}

function* actionEmployeeGetVisitRows({payload : {data, cb}}){
    try{
        const res = yield call(visitsCall, data)
        if(res.status == 200){
            yield put(getEmployeeVisitRowsSuccess(res.data))
        }
    }catch(error){
        console.log(error)
    }
}

function* resetDevice({payload : {data, rdcb}}){
    try{
        const res = yield call(getCall, data)
        if(res.status == 200){
            yield call(rdcb, res.response)
        }
    }catch(error){
        console.log(error)
    }
}




function* EmployeeSaga() {
    yield takeEvery(GET_EMPLOYEE_VISITS_ROWS, actionEmployeeGetVisitRows)
    yield takeEvery(GET_EMPLOYEE_ALL_ROWS, actionEmployeeGetAllRows)
    yield takeEvery(ADD_EMPLOYEE_RECORD, actionEmployeeAddRecord)
    yield takeEvery(REMOVE_EMPLOYEE_RECORD, actionEmployeeRemoveRecord)
    yield takeEvery(GET_OPTIONS, getAllOptions)
    yield takeEvery(RESET_DEVICE, resetDevice)
}
  
export default EmployeeSaga
  