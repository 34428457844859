import 
{ 
    GET_ATTENDANCE_COUNT,
    GET_ATTENDANCE_COUNT_SUCCESS
} from "./actionType"

export const getAttendanceCount = data => {
    return {
        type: GET_ATTENDANCE_COUNT,
        payload: data
    }
}

export const getAttendanceCountSuccess = data => {
    return {
        type: GET_ATTENDANCE_COUNT_SUCCESS,
        payload: data
    }
}