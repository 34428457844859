import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
    Container,
    Row,
    Col,
    Modal
} from "reactstrap"
import { Link } from "react-router-dom";
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
//i18n
import { withTranslation } from "react-i18next"
import Datatable from "components/Datatables/datatable"
import { useDispatch, useSelector } from 'react-redux';
import { EMPLOYEE_URL, OPTIONS_URL, ATTENDANCE_URL, ACCESS_DEVICE_URL } from "helpers/url_helper"
import Form from "./component/Form";
import { AvForm, AvField } from "availity-reactstrap-validation"
import { getEmployeeAllRows, addEmployeeRecord, removeEmployeeRecord, getAllOptions, getEmployeeVisitRows, doResetDevice } from 'store/actions';
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import { get } from 'helpers/api_helper'

const Employee = props => {
    const dispatch = useDispatch()
    const { loading, employees, locations, designations, office_locations, visits } = useSelector(state => state.Employee);
    const [open, isOpen] = useState(false)
    const [editableObj, setEditableObj] = useState({})
    const [dataRows, setDataRows] = useState([])
    const [allOPtions, setAllOPtions] = useState([])
    const [modalReport, setModalReport] = useState(false)
    const [modalReportEmpId, setModalReportEmpId] = useState("")
    const [visitRows, setVisitRows] = useState([])
    const [resetLoading, setResetLoading] = useState(false)
    const [accessDeviceLoading, setAccessDeviceLoading] = useState(false)

    const cb = (data) => {
        setEditableObj(e=> data)
        isOpen( e => !open)
    }
    
    useEffect(() => {
        setDataRows(curr => employees)
    },[employees])
    
    useEffect(() => {
        setVisitRows(curr => visits)
    },[visits])
    
    useEffect(() => {
        setAllOPtions({locations, designations, office_locations})
    },[locations, designations, office_locations])
    
    useEffect(() => {
        dispatch(getAllOptions({url:OPTIONS_URL, isSingle: false}, props.history))
        dispatch(getEmployeeAllRows({url:EMPLOYEE_URL, isSingle: false}, props.history))
    },[])
    
    const handdleAddButton = () => {
        isOpen(!open)
    }

    const addupdate_callback = () => {
        dispatch(getEmployeeAllRows({url:EMPLOYEE_URL, isSingle: false}, props.history))
        isOpen(!open)
    }

    // Open RightSide Panel
    const submitForm = (data) => {
        const Obj = {
            "firstname"  : data.target.firstname?.value,
            "middlename"  : data.target.middlename?.value, 
            "lastname"  : data.target.lastname?.value,
            "gender"  : data.target.gender?.value, 
            "code"  : data.target.code?.value,
            "mobile"  : data.target.mobile?.value,
            "phone"  : data.target.phone?.value,
            "email"  : data.target.email?.value,
            "designation"  : data.target.designation?.value, 
            "location"  : data.target.location?.value,
        }
        dispatch(addEmployeeRecord({Obj:Obj, url: EMPLOYEE_URL+`${data?.target?.id?.value ? '?q='+data?.target?.id?.value : '' }`, id: data?.target?.id?.value}, props.history, addupdate_callback))
    }

    // Update Data , Get Singlw ROw using url params ?q=?
    const handleEdit = data => {
        if(data > 0) dispatch(getEmployeeAllRows({url:`${EMPLOYEE_URL}?q=${data}`, isSingle: true}, props.history, cb))
    }

    // Remove Data
    const handleRemove = (data, status) => {
        const cbr = (v) => {}
        if(data > 0) dispatch(removeEmployeeRecord({url:`${EMPLOYEE_URL}?q=${data}&status=${status}`, data: {id:data, status:status}}, cbr))
    }

    const handleReport = (data) => {
        setModalReportEmpId(c => data)
        setVisitRows(c => [])
        setModalReport(c => !modalReport)
    }


    // table Column Data
    let company_columns = [
        {
            dataField: 'firstname',
            text: 'Employee Name',
            sort: true,
            formatter:(cell, row) => {
                return <span onClick={() => handleReport(row.id)} >{`${row.firstname} ${row.middlename} ${row.lastname}`}</span>
            }
        },{
            dataField: 'code',
            text: 'Employee Code',
            sort: true
        },{
            dataField: 'email',
            text: 'Email',
            sort: true
        },{
            dataField: 'mobile',
            text: 'Mobile',
            sort: true
        },{
            dataField: 'gender',
            text: 'Gender',
            sort: true,
             formatter:(cell, row) => {
                return row.gender == 1 ? 'Male' : 'Female';
            }
        },{
            dataField: 'location',
            text: 'Location',
            sort: true
        },{
            dataField: 'designation',
            text: 'Designation',
            sort: true,
        },{
            dataField: 'actions',
            text: "Actions",
            isDummyField: true,
            formatExtraData: {loading, resetLoading, accessDeviceLoading},
            formatter: (cell, row,  rowIndex, extraData) => {
                return <>
                    <Link
                    to="#"
                    className={`btn btn-outline-${ row.is_mobile == 0 ? 'secondary' : 'success'} btn-sm reload`}
                    onClick={() => handleDeviceAccess(row.id)} 
                    title={row.is_mobile == 0 ? 'Grant Access' : 'Revok Access'} >
                        <i className={`fas ${ (accessDeviceLoading && accessDeviceLoading == row.id) ? 'fa-spinner fa-spin' : 'fa-mobile'}`} />
                    </Link>
                    {" "}
                    <Link
                    to="#"
                    className={`btn btn-outline-${(resetLoading && resetLoading == row.id) ? 'secondary' : 'warning'} btn-sm reload`}
                    onClick={() => handleResetDevice(row.id)} 
                    title={"Reset Device"} >
                        <i className={`fas ${ (resetLoading && resetLoading == row.id) ? 'fa-spinner fa-spin' : 'fa-sync-alt'}`} />
                    </Link>
                    {" "}
                    <Link
                    to="#"
                    className="btn btn-outline-secondary btn-sm edit"
                    onClick={() => handleEdit(row.id)} 
                    title="Edit" >
                    <i className="fas fa-pencil-alt"></i>
                    </Link>
                    {" "}
                    <Link
                    to="#"
                    className={`btn btn-outline-${row.status == 0 ? "danger" : "success"} btn-sm edit`}
                    onClick={() => handleRemove(row.id, row.status == 1? 0 : 1)} 
                    title={row.status == 0 ?'De-active' : 'Active'} >
                    <i className={`fas fa-${row.status == 0 ? 'trash-alt' : 'check'}`}></i>
                    </Link>
                </>
            }
        }
    ];

    let visit_columns = [
        {
            dataField: 'actions',
            text: 'SR No.',
            sort: true,
            csvExport: false,
            formatter:(cell, row,  rowIndex, extraData) => {
                return rowIndex + 1
            }
        },
        {
            dataField: 'name',
            text: 'Employee Name',
            sort: true,
        },{
            dataField: 'emp_code',
            text: 'Employee Code',
            sort: true
        },{
            dataField: 'date',
            text: 'Date',
            sort: true
        },{
            dataField: 'time',
            text: 'Time',
            sort: true
        },{
            dataField: 'client',
            text: 'Dentist Name',
            sort: true,
        }
    ];

    const downLoadReport = (fromDate, toDate) => {
        setVisitRows(c => [])
        dispatch(getEmployeeVisitRows({url:`${ATTENDANCE_URL}/visit_report`, data: { userId: modalReportEmpId, fromDate:fromDate, toDate:toDate}, isSingle: false}, props.history))
    }
    
    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }
    
    const handleDeviceAccess = async(data) => {
        setAccessDeviceLoading(data)
        const res = await get(`${ACCESS_DEVICE_URL}/${data}`)
        if(res.status == 200){
            setAccessDeviceLoading(false)
            toast(res.message, res.success)
        }
    }

    const handleResetDevice = (data) => {
        setResetLoading(data)
        const rdcb = (data) => {
            setResetLoading(false)
            toast(data)
        }
        dispatch(doResetDevice({url:`${EMPLOYEE_URL}/resetdevice/${data}`, isSingle: false}, props.history, rdcb))
    }

    const toast = (msg, status) => {
        toastr.options = {
            positionClass : 'toast-top-full-width',
            hideDuration: 300,
            timeOut: 1000
        }
        toastr.clear()

        if(status){
            setTimeout(() => toastr.success(msg), 300)
        } else { 
            setTimeout(() => toastr.warning(msg), 300)
        }
    } 

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Dashboard | Employees</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumbs
                        title="Employees"
                    />

                    <Row>
                        <Col xl="12">
                            <Datatable 
                                title={`Employees`}
                                handdleAddButton={handdleAddButton}
                                columns={company_columns}
                                rows={dataRows}
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
            
            {
                // allOPtions.location.length > 0 &&
                <Form
                    title={`${editableObj?.length ? 'Edit' : 'Add'} Employee`}
                    Position={"right"}
                    isOpen={open}
                    width={50}
                    allOption={allOPtions}
                    editableObj={editableObj[0]}
                    onDrawerClose={(e) => {
                        isOpen(e => false)
                        setEditableObj(e => [])
                    }}
                    submitForm={submitForm}
                />
            }
            
            <Modal
                zIndex="9999"
                className="modal-xl"
                isOpen={modalReport}
                toggle={() => {
                    setModalReport(c => !modalReport);
                }}
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myModalLabel"> Visit Report Location</h5>
                    <button
                        type="button"
                        onClick={() => {
                            setModalReport(false);
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                    <div className="modal-body">
                            <div className="row">
                                <AvForm
                                    key="reportForm"
                                    className="form-horizontal"
                                    style={{display: "flex", justifyContent: "space-between"}}
                                    onLoad={e => formik.resetForm()}
                                    onValidSubmit={(e, v) => {
                                        downLoadReport(e.target.from_date.value, e.target.to_date.value)
                                    }}
                                >
                                <div className="col-md-4">
                                    <AvField
                                        name="from_date"
                                        label="From Range"
                                        className="form-control"
                                        placeholder="Please Select Date"
                                        type="date"
                                        value=""
                                        required={true} />
                                </div>
                                <div className="col-md-4">
                                    <AvField
                                        name="to_date"
                                        label="To Date"
                                        className="form-control"
                                        placeholder="Please Select Date"
                                        type="date"
                                        value=""
                                        required={true} />
                                </div>
                                <div className="col-md-3">
                                    <button
                                        style={{marginTop: "28px"}}
                                        className="btn btn-primary btn-block "
                                        type="submit"
                                        >Get</button>
                                </div>
                                </AvForm>
                            </div>
                            <Datatable 
                                key="reportTable"
                                title={`Visits`}
                                handdleAddButton={handdleAddButton}
                                columns={visit_columns}
                                rows={visitRows}
                                searchActive={false}
                            />
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            onClick={() => {
                                setModalReport(!modalReport);
                                removeBodyCss()
                            }}
                            className="btn btn-secondary "
                            data-dismiss="modal"
                            >
                            Close
                        </button>
                    </div>
            </Modal>

        </React.Fragment>
    )
}

Employee.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(Employee)
