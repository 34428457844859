import React, { useEffect } from "react";

const  GoogleMap = ({ping}) => {
  useEffect(()=>{
      const ifameData=document.getElementById("iframeId")
      ifameData.src=`https://maps.google.com/maps?q=${ping}&hl=es;&output=embed`
  })
  return(
      <div>
          <iframe id="iframeId" height="500px" width="100%"></iframe>
      </div>
  );
}
export default GoogleMap;