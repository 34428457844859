import {
    GET_ATTENDANCE_COUNT,
    GET_ATTENDANCE_COUNT_SUCCESS
} from './actionType'

const INIT_STATE = {
    dashbaord: [],
    loading: false,
}
  
const DashboardR = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_ATTENDANCE_COUNT:
            return {
                ...state,
                loading: true,
            }
        case GET_ATTENDANCE_COUNT_SUCCESS:
            return {
                ...state,
                loading: false,
                dashbaord: action.payload,
            }
        default:
            return state
    }
}

export default DashboardR