export const API_URL = process.env.REACT_APP_url
// Login
export const POST_LOGIN = "/login"

//REGISTER
export const POST_FAKE_REGISTER = "/post-fake-register"


// Company
export const COMPANY_URL = "/company"

// Product
export const PRODUCT_URL = "/products"

// Company
export const MASTER_URL = "/masters?access_type="

// Employee
export const EMPLOYEE_URL = "/employee"

export const OPTIONS_URL = "/employee/options"

// 
export const ATTENDANCE_URL = "/attendance"
export const DASHBOARD_ATTENDANCE_LIST = "/analysis/attendancelist"
export const DASHBOARD_GRAPH_DATA = "/analysis/yearlygraphdata"

// Punches
export const GET_PUNCHES_URL = "/punch"

// Dashboard
export const GET_ATTENDANCE_COUNT_URL = "/analysis/today"

export const ACCESS_DEVICE_URL = "/employee/grantAccess"

export const CLIENTS_URL = "/clients/all"
export const CLIENTS_ACTIVE_URL = "/clients/status"
export const LOGS_URL = "/logs"