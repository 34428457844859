import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
    Container,
    Row,
    Col,
    Modal
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

import Datatable from "components/Datatables/datatable"
import { useDispatch, useSelector } from 'react-redux';

import { ATTENDANCE_URL } from "helpers/url_helper"

import { getAttendance, getVisitedLocations } from './store/actions';
import Map2 from "../../components/Map2";
import Map from "../../components/Map";
import { getDate } from 'helpers/basic_helper'
import { get } from 'helpers/api_helper'

const Attendance = props => {
    const dispatch = useDispatch()
    const { loading, attendance, visits } = useSelector(state => state.Attendance);
    const [dataRows, setDataRows] = useState([])
    const [modalMap, setModalMap] = useState(false)
    const [cd, setCD] = useState("")
    const [points, setPoints] = useState([])
    const [zoom, setZoom] = useState(6)
    const [multiplePoints, setMultiplePoints] = useState([])
    const [mapLoading, setMapLoading] = useState("")
    const [visitPoint, setVisitPoint] = useState("")

    useEffect(() => {
        dispatch(getAttendance({url:`${ATTENDANCE_URL}?${getDate('yyyy-MM-DD')}`, isSingle: false}, props.history))
    },[])

    useEffect(() => {
        if(attendance.length > 0){
            setDataRows(attendance)
        }
        // setDataRows(attendance)
    }, [attendance])

    useEffect(() => {
        if(modalMap === false){
            setPoints([])
            setVisitPoint("")
        }
    },[modalMap])

    useEffect(() => {
        visits.map((v, i) => {
            let cr = v.visit.split(",");
            multiplePoints.push({co:{lat:cr[0],lng:cr[1]}, 'address': v.address, 'client': v.client})
        })
        if(multiplePoints.length > 0){
            setZoom(12)
            setPoints(multiplePoints)
            setModalMap(true)
        }
    }, [visits])

    const handleVisits = (id) => {
        setPoints([])
        setMapLoading(id);
        setMultiplePoints(() => [])
        dispatch(getVisitedLocations({url:`${ATTENDANCE_URL}/visits?${id}/${cd}`, isSingle: false}, props.history, () => {
            setMapLoading(false)
        }))
    }
    
    // table Column Data
    let company_columns = [
        {
            dataField: 'name',
            text: 'Employee',
            sort: true,
            formatter: (cell, row,  rowIndex, extraData) => <span style={{textTransform: 'uppercase'}}>{row.name}</span>
        },{
            dataField: 'emp_code',
            text: 'Employee Code',
            sort: true,
            formatter: (cell, row,  rowIndex, extraData) => <span style={{textTransform: 'uppercase', fontWeight: 'bold'}}>{row.emp_code}</span>
        },{
            dataField: 'date',
            text: 'Date',
            sort: true
        },{
            dataField: 'Visited',
            text: "Visited",
            isDummyField: true,
            formatExtraData: loading,
            formatter: (cell, row,  rowIndex, extraData) => {
                return <>
                    <button
                        style={{padding: "0px", fontSize: "18px"}}
                        to="#"
                        className="btn btn-sm"
                        onClick={() => handleVisits(row.user_id)}
                        title="Edit" >
                            {
                                mapLoading == row.user_id ?
                                <i className="fa fa-spinner fa-spin"></i> :
                                <i className="fas fa-map"></i>
                            }
                    </button>
                </>
            }
        },{
            dataField: '_in',
            text: 'IN',
            sort: true,
            formatter: (cell, row,  rowIndex, extraData) =>
                <div onClick= {()=> getLatlong(row.in_location)}>
                    <span style={{fontWeight: 'bold'}}>{row._in} {"  "}</span> 
                    <i className="fas fa-map-marker-alt" style={{fontSize: '18px'}} ></i>
                </div>
        },{
            dataField: '_out',
            text: 'OUT',
            sort: true,
            formatter: (cell, row,  rowIndex, extraData) =>
                <div onClick= {()=> getLatlong(row.out_location)} >
                    <span style={{fontWeight: 'bold'}}>{row._out} {" "} </span>
                    <i className="fas fa-map-marker-alt" style={{fontSize: '18px'}} ></i>
                </div>
        }
    ];

    const returnDate = async (data = false) => {
        const date = data ? data : getDate('yyyy-MM-DD')
        // setCD(date)
        if(date)
            dispatch(getAttendance({url:`${ATTENDANCE_URL}?${date}`, isSingle: false}, props.history))
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    const getLatlong = (data) => {
        setVisitPoint(data)
        setZoom(19)
        setModalMap(true)
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Dashboard | Attendance</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumbs
                        title="Reports"
                        breadcrumbItem={"Attendance"}
                    />

                    <Row>
                        <Col xl="12">
                            <Datatable 
                                title={``}
                                columns={company_columns}
                                rows={dataRows}
                                isAdd={false}
                                isDate={true}
                                returnDate={returnDate}
                                getLatlong={getLatlong} 
                            />
                        </Col>
                    </Row>
                </Container>
            </div>

            <Modal
                zIndex="9999"
                isOpen={modalMap}
                toggle={() => {
                    setModalMap(!modalMap);
                }}
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myModalLabel"> Punch Location</h5>
                    <button
                        type="button"
                        onClick={() => {
                            setModalMap(false);
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    {
                        points.length > 0 && <Map2 ping={points} zoom={zoom} />
                    }
                    {
                        visitPoint.length > 0 && <Map ping={visitPoint} zoom={zoom} />
                    }
                </div>
                <div className="modal-footer">
                <button
                    type="button"
                    onClick={() => {
                        setModalMap(!modalMap);
                        removeBodyCss()
                    }}
                    className="btn btn-secondary "
                    data-dismiss="modal"
                >
                    Close
                </button>
                </div>
            </Modal>


        </React.Fragment>
    )
}

Attendance.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(Attendance)
