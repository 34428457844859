import { GET_VISITES, GET_VISITES_SUCCESS } from "./actionType";

const INIT_STATE = {
    visits: [],
    loading: false,
}
  
const VisitsR = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_VISITES:
            return {
                ...state,
                loading: true
            }
        case GET_VISITES_SUCCESS:
            return {
                ...state,
                loading: false,
                visits: action.payload.data
            }
        default:
            return state
    }
}

export default VisitsR