import { GET_ATTENDANCE, GET_ATTENDANCE_SUCCESS, GET_VISITS, GET_VISITS_SUCCESS } from './actionType'

export const getAttendance = (data, history = false) => {
    return {
        type: GET_ATTENDANCE,
        payload: {data, history},
    }
}

export const getAttendanceSuccess = payload => ({
    type: GET_ATTENDANCE_SUCCESS,
    payload: payload,
})

export const getVisitedLocations = (data, history = false, cb = false) => ({
    type: GET_VISITS,
    payload: {data, history, cb},
})

export const getVisitedLocationsSuccess = payload => ({
    type: GET_VISITS_SUCCESS,
    payload: payload,
})

