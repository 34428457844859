import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
    Container,
    Row,
    Col
} from "reactstrap"
import { Link } from "react-router-dom";
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import Datatable from "components/Datatables/datatable"
import { useDispatch, useSelector } from 'react-redux';
import { API_URL, CLIENTS_URL, CLIENTS_ACTIVE_URL } from "helpers/url_helper"
import { getClients } from 'store/actions'
import { getDate } from 'helpers/basic_helper'
import { get } from 'helpers/api_helper'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'

const isType = 'Clients'

const Clients = props => {
    const dispatch = useDispatch()
    const { loading, clients } = useSelector(state => state.Clients);
    const [open, isOpen] = useState(false)
    const [editableObj, setEditableObj] = useState({})
    const [dataRows, setDataRows] = useState([])
    const [activeLoading, setActiveLoading] = useState(false)

    useEffect(() => {
        dispatch(getClients({url: `${CLIENTS_URL}`}))
    },[])

    useEffect(() => {
        if(clients.data?.length > 0){
            setDataRows(clients.data.map((v, i) => {
                v['sr'] = 1+i
                return v
            }))
        }
    },[clients])

        // table Column Data
    let company_columns = [
        {
            dataField: 'sr',
            text: '#',
            sort: true,
        },{
            dataField: 'logo',
            text: '',
            formatter: (cell, row,  rowIndex, extraData) => <img src={`${API_URL}${row.logo}`} width="60" />
        },{
            dataField: 'client_name',
            text: 'Client',
            sort: true,
            formatter: (cell, row,  rowIndex, extraData) => <span style={{textTransform: 'uppercase'}}>{row.client_name}</span>
        },{
            dataField: 'code',
            text: 'Code',
            formatter: (cell, row,  rowIndex, extraData) => <span style={{textTransform: 'uppercase', fontWeight: 'bold'}}>{row.code}</span>
        },{
            dataField: 'access_code',
            text: 'Activetion Code',
            formatter: (cell, row,  rowIndex, extraData) => <span style={{textTransform: 'uppercase', fontWeight: 'bold'}}>{row.access_code}</span>
        },{
            dataField: 'start_date',
            text: 'Activetion Date',
            formatter: (cell, row,  rowIndex, extraData) => getDate(row.start_date, 'DD-MM-yyyy')
        },{
            dataField: 'renewal_date',
            text: 'Renewal Date',
            formatter: (cell, row,  rowIndex, extraData) => getDate(row.start_date, 'DD-MM-yyyy')
        },{
            dataField: 'emp_code',
            text: 'Allowed Users',
            sort: true,
            formatter: (cell, row,  rowIndex, extraData) => <span style={{fontWeight: 'bold'}}>{row.login_limits}</span>
        },{
            dataField: 'active',
            text: 'Active Users',
            formatter: (cell, row,  rowIndex, extraData) => <span style={{fontWeight: 'bold'}}>{row.active}</span>
        },{
            dataField: 'actions',
            text: "Actions",
            isDummyField: true,
            formatExtraData: loading,
            formatter: (cell, row,  rowIndex, extraData) => {
                return (
                    <Link
                        to="#"
                        className={`btn btn-outline-${ row.status == 0 ? 'success' : 'danger'} btn-sm reload`}
                        onClick={() => handdleStatus(row.id)} 
                        title={row.is_mobile == 0 ? 'Active' : 'In-active'} >
                        <i className={`fas ${ (activeLoading && activeLoading == row.id) ? 'fa-spinner fa-spin' : 'fa-lock'}`} />
                    </Link>)
            }
        }
    ];

    const handdleAddButton = () => {}
    const handdleStatus = async(data) => {
        setActiveLoading(data)
        const res = await get(`${CLIENTS_ACTIVE_URL}/${data}`)
        if(res.status == 200){
            setActiveLoading(false);
            toast(res.message, res.success)
            dispatch(getClients({url: `${CLIENTS_URL}`}))
        }
    }

    const toast = (msg, status) => {
        toastr.options = {
            positionClass : 'toast-top-full-width',
            hideDuration: 300,
            timeOut: 1000
        }
        toastr.clear()

        if(status){
            setTimeout(() => toastr.success(msg), 300)
        } else { 
            setTimeout(() => toastr.warning(msg), 300)
        }
    } 

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Dashboard | {isType}</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumbs
                        title="Admin"
                        breadcrumbItem="Clients"
                    />

                    <Row>
                        <Col xl="12">
                            <Datatable 
                                title={`All ${isType}`}
                                handdleAddButton={handdleAddButton}
                                columns={company_columns}
                                rows={dataRows}
                            />
                        </Col>
                    </Row>
                </Container>
            </div>

        </React.Fragment>
    )
}

export default Clients
