export const GET_EMPLOYEE_ALL_ROWS = 'GET_EMPLOYEE_ALL_ROWS'
export const GET_EMPLOYEE_SINGLE_ROW = 'GET_EMPLOYEE_SINGLE_ROW'
export const GET_EMPLOYEE_ALL_ROWS_SUCCESS = 'GET_EMPLOYEE_ALL_ROWS_SUCCESS'
export const ADD_EMPLOYEE_RECORD = 'ADD_EMPLOYEE_RECORD'
export const ADD_EMPLOYEE_RECORD_SUCCESS = 'ADD_EMPLOYEE_RECORD_SUCCESS'
export const REMOVE_EMPLOYEE_RECORD = 'REMOVE_EMPLOYEE_RECORD'
export const EDIT_EMPLOYEE_RECORD = 'EDIT_EMPLOYEE_RECORD'
export const UPDATE_EMPLOYEE_RECORD = 'UPDATE_EMPLOYEE_RECORD'
export const PUSH_EMPLOYEE_NEW_RECORD = 'PUSH_EMPLOYEE_NEW_RECORD'
export const GET_OPTIONS = 'GET_OPTIONS'
export const GET_OPTIONS_SUCCESS = 'GET_OPTIONS_SUCCESS'
export const GET_EMPLOYEE_VISITS_ROWS = 'GET_EMPLOYEE_VISITS_ROWS'
export const GET_EMPLOYEE_VISITS_ROWS_SUCCESS = 'GET_EMPLOYEE_VISITS_ROWS_SUCCESS'
export const RESET_DEVICE = 'RESET_DEVICE'
