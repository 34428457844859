import PropTypes from "prop-types"
import React, { useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
// Pages Components
import Attendance from "./components/Attendance"
import PrasentEmployeeList from "./components/PrasentEmployeeList"
import Graph from "./components/Graph"
//i18n
import { withTranslation } from "react-i18next"

const Dashboard = () => {
    return (
        <React.Fragment>
        <div className="page-content">
            <MetaTags>
                <title>Dashboard | Title</title>
            </MetaTags>
            <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
                title="Dashboards"
                breadcrumbItem="Dashboards"
            />
            
            <Row>
                <Col xl="4">
                <Row>
                        <Col xl="12">
                            <Attendance />
                        </Col>
                    </Row>
                    <Row>
                        <Col xl="12">
                            <PrasentEmployeeList />
                        </Col>
                    </Row>
                </Col>
                <Col xl="8">
                    <Graph />
                </Col>
            </Row>
            </Container>
        </div>
        </React.Fragment>
    )
}

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(Dashboard)
